.spinner-wrapper {
  margin: auto;
  z-index: 900000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.65);
  display: flex;
}

.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}


.loader:after {
  content: '';
  width: 100%;
  height: 100%;
  background-image: url('/public/icons/minilogo.svg');
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 1.5s linear infinite;
}


@keyframes jump7456 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes shadow324 {

  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}